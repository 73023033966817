<template>
    <div class="article_area">
        <h5 class=" _cast _cast_0">
            <span class="hi_bible">Hi Bible</span>
            <span class="text">에 대하여</span>
        </h5>
        <p class=" _cast _cast_1">
            <span class="_line">하이바이블은 살림교회에서 제작하는 영상 및 성경공부 자료를 나누는 사이트입니다.</span>
            <span class="_line">하이바이블은 매주일 교회력과 성경 본문에 기초한 말씀을 전하는 영상과 어린이들이 매주일 함께 할 수 있는</span>
            <span class="_line">성경통독 자료 징검다리 성경공부를 무료로 하이바이블 사이트와 갓피플, 유튜브, 페이스북등에 올리고 있습니다.</span>
        </p>
        <p class=" _cast _cast_2">
            <span class="_line">하이바이블의 자료는 어린이를 위한 사역자가 없는 교회나, 매주일 신앙교육을 위한 자료를</span>
            <span class="_line">필요하는 가정들, 어린이에게 필요한 컨텐츠를 필요로 하는 선교지등에서 사용하실수 있습니다.</span>
        </p>
        <p class=" _cast _cast_3">
            <span class="_line">하이바이블은 장로회신학대학교에서 기독교교육을 전공하고 신학대학원 과정을 마친</span>
            <span class="_line">최아론목사와 김혜진전도사가 사역자이며, 영상 컨텐츠 제작(그림,촬영,편집등)을 위한 전문가들로</span>
            <span class="_line">구성되어 있습니다. </span>
        </p>
        <p class=" _cast _cast_4">
            <span class="_line">하이바이블을 통해서 이 땅의 어린이들에게 하나님의 말씀이 깊이 있고 다양하게 전해지기를 소망해봅니다.</span>
            <span class="_line">기타 문의사항은 최아론목사 (010-9036-2458,hi-bible@naver.com) 에게 연락하세요.</span>
        </p>
        <p class="last_word _cast _cast_5">
            <span class="_line">우리의 모든 삶 그리스도 안에서</span>
            <span class="_line"> – 하이바이블과 함께하는 사람들</span>
        </p>
    </div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.about[lang],
			desc: '하이바이블은 살림교회에서 제작하는 영상 및 성경공부 자료를 나누는 사이트입니다.',
			keyword: '하이바이블에 대하여, about hibible, 성경이야기',
			thumb: '/hibible/resource/images/ab_intro.svg',
		});
	},
}
</script>