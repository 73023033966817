<template>
    <div class="article_area">
        <h5 class=" _cast _cast_0">
            <span class="text">About</span>
            <span class="hi_bible">Hi Bible</span>
        </h5>
        <p class=" _cast _cast_1">
            <span class="_line">HiBible is a website offering Bible study materials and videos produced by Salim Church.</span>
            <span class="_line">Videos telling the word of God based on the church calendar and the Bible, </span>
            <span class="_line">and children's Bible study and eading material "Stepping Stones, Bible Reading!" </span>
            <span class="_line">are uploaded for free every Sunday on the HiBible website, Godpeople, Youtube, and Facebook.</span>
        </p>
        <p class=" _cast _cast_2">
            <span class="_line">HiBible materials can be used by churches without a children's pastor, </span>
            <span class="_line">families needing weekly Bible study materials, and missionaries around </span>
            <span class="_line">the world who need Bible contents for children.</span>
        </p>
        <p class=" _cast _cast_3">
            <span class="_line">HiBible is organized by Pastor Ahron Choi and Assistant Pastor Hyejin Kim, </span>
            <span class="_line">who have both received Mater of Theology in Christian Education at the Presbyterian University </span>
            <span class="_line">and Theological Seminary, Korea. The videos are created by a team of art and media professionals </span>
            <span class="_line">from pre- to post-production.</span>
        </p>
        <p class=" _cast _cast_4">
            <span class="_line">Through HiBible, we hope that the word of God is spread across the globe and takes deep root </span>
            <span class="_line">in the hearts of all children in the world. </span>
            <span class="_line">For further inquiries, contact Pastor Ahron Choi (010-9036-2458, hi-bible@naver.com).</span>
        </p>
        <p class="last_word _cast _cast_5">
            <span class="_line">Our Life Together in Christ</span>
            <span class="_line"> – Through HiBible!</span>
        </p>
    </div>
</template> 

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.about[lang],
			desc: 'HiBible is a website offering Bible study materials and videos produced by Salim Church.',
			keyword: 'about hibible, bible story',
			thumb: '/hibible/resource/images/ab_intro.svg',
		});
	},
}
</script>