<template>
    <div class="article_area">
        <h5 class=" _cast _cast_0">
            <span class="hi_bible">Hi Bible</span>
            <span class="text">နှင့် ပတ်သက်၍</span>
        </h5>
        <p class=" _cast _cast_1">
            <span class="_line">HiBibleသည် Salim ဘုရားကျောင်းမှ ပြုလုပ်သည့် ဗီဒီယိုနှင့် ကျမ်းစာလေ့လာခြင်း </span>
            <span class="_line">အချက်အလက်များကို မျှဝေသည့် အင်တာနက်စာမျက်နှာဖြစ်ပါသည်။</span>
            <span class="_line">HiBible သည် အပတ်စဉ် အသင်းတော်ပြက္ခဒိန်နှင့် ကျမ်းစာ၏ အခြေခံထားသော </span>
            <span class="_line">နှုတ်ကပတ်တော်များကို လက်ဆင့်ကမ်းပေးသည့် ဗီဒီယိုနှင့် ကလေးငယ်များ အပတ်စဉ် </span>
            <span class="_line">အတူလုပ်ဆောင်နိုင်သည့် ကျောက်တုံးတံတား ကျမ်းစာဖတ်ရှုလေ့လာခြင်း အချက်အလက်များကို </span>
            <span class="_line"> HiBible website, Godpeople, Youtube, Facebookမှတစ်ဆင့် အခမဲ့ တင်ပေးလျက်ရှိပါသည်။</span>
        </p>
        <p class=" _cast _cast_2">
            <span class="_line">HiBible ၏ အချက်အလက်များသည် ကလေးငယ်များအတွက် အမှုဆောင်မရှိသည့် ဘုရားကျောင်း၊ </span>
            <span class="_line">အပတ်စဉ် ဘာသာရေးသင်ကြားမှုအတွက် သင်ထောက်ကူလိုအပ်သော မိသားစုများ၊ ကလေးများအတွက် </span>
            <span class="_line">လိုအပ်သော အ‌ချက်အလက်များကို ရှာဖွေသော ဘာသာ‌ရေးအသင်းဝင်များမှ အသုံးပြုနိုင်ပါသည်။</span>
        </p>
        <p class=" _cast _cast_3">
            <span class="_line">HiBible သည် Presbyterian တက္ကသိုလ်မှ ခရစ်ယာန်ပို့ချရေးဘာသာကို မေဂျာဖြင့် </span>
            <span class="_line">ခရစ်ယာန်မာစတာတန်းကိုပြီးမြောက်ထားသော သင်းအုပ်ဆရာ ချွဲအာရွန်းနှင့် ဂင်ဟယ်ဂျင်း </span>
            <span class="_line">လက်ထောက်သင်းအုပ်ဆရာတို့မှ အမှုဆောင်များအဖြစ် ပါဝင်ပြီး ဗီဒီယို၊ ပုံစသည်ကို တည်းဖြတ်ရိုက်ကူးရေး </span>
            <span class="_line">ကျွမ်းကျင်သူများဖြင့် ဖွဲ့စည်းထားပါသည်။</span>
            <span class="_line">အခြား စုံစမ်းမေးမြန်းလိုပါက သင်းအုပ်ဆရာ ချွဲအာရွန်း (၀၁၀-၉၀၃၆-၂၄၅၈,hi-bible@naver.com) </span>
            <span class="_line">သို့ ဆက်သွယ် စုံစမ်းနိုင်ပါသည်။</span>
        </p>
        <p class=" _cast _cast_4">
            <span class="_line">HiBible မှတစ်ဆင့် ဤကမ္ဘာပေါ်ရှိ ကလေးငယ်များသည် ဘုရားသခင်၏ နှုတ်ကပတ်တော်ကို </span>
            <span class="_line">နက်ရှိုင်းစွာ ခံစားရရှိကြစေရန် မျှော်လင့်ပါသည်။</span>
        </p>
        <p class="last_word _cast _cast_5">
            <span class="_line">ကျွန်ုပ်တို့၏ ဘဝများသည် ခရစ်တော်တွင်း၌သာ</span>
            <span class="_line"> – HiBible နှင့်အတူ လက်တွဲသူများ</span>
        </p>
    </div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.about[lang],
			desc: 'HiBibleသည် Salim ဘုရားကျောင်းမှ ပြုလုပ်သည့် ဗီဒီယိုနှင့် ကျမ်းစာလေ့လာခြင်း အချက်အလက်များကို မျှဝေသည့် အင်တာနက်စာမျက်နှာဖြစ်ပါသည်။',
			keyword: 'HiBible နှင့် ပတ်သက်၍',
			thumb: '/hibible/resource/images/ab_intro.svg',
		});
	},
}
</script>