<template>
    <div class="article_area">
        <h5 class=" _cast _cast_0">
            <span class="hi_bible">Hi Bible</span>
            <span class="text">-н талаар</span>
        </h5>
        <p class=" _cast _cast_1">
            <span class="_line">Hi Bible(Сайн уу Библи) нь Салим чуулганаас эрхлэн гаргаж буй бичлэг болон бусад библийн </span>
            <span class="_line">хичээлийн авлагийг хуваалцдаг цахим хуудас билээ. Hi Bible(Сайн уу Библи) нь Эзэний өдөр </span>
            <span class="_line">бүр чуулганы хуанли болон библийн үгэнд суурилсан номлолыг дамжуулдаг бичлэг болон хүүхдүүд </span>
            <span class="_line">Эзэний өдөр бүр унших "Гишгүүр Чулуу" библийн хичээлийг үнэ төлбөргүйгээр Hi Bible, </span>
            <span class="_line">God People, Youtube, Facebook зэрэг цахим хуудсуудад тавьж байгаа билээ. </span>
        </p>
        <p class=" _cast _cast_2">
            <span class="_line">Hi Bible(Сайн уу Библи)-ийн гарын авлага нь хүүхдийн үйлчлэгч байхгүй чуулган, христчин </span>
            <span class="_line">боловсролын гарын авлага хэрэгтэй гэр бүл, хүүхдүүдэд хэрэгтэй хөтөлбөр шаардлагатай </span>
            <span class="_line">бусад орнууд хэрэглэх боломжтой. </span>
        </p>
        <p class=" _cast _cast_3">
            <span class="_line">Hi Bible(Сайн уу Библи)-д Өмнөд Солонгос   улсын Библийн нэгдсэн сургуулийн христчин </span>
            <span class="_line">боловсролыг эзэмшсэн магистр зэрэгтэй Чой Арун пастор болон Ким Хэ Жин ахлагч нар үйлчилдэг </span>
            <span class="_line">бөгөөд бичлэг хийдэг (зураг, зураг авалт, бичлэг оруулах) мэргэжлийн баг ажилладаг билээ.</span>
        </p>
        <p class=" _cast _cast_4">
            <span class="_line">Hi Bible(Сайн уу Библи)-р дамжуулан энэ газар дээрх бүхий л хүүхдүүдэд Бурханы үг илүү </span>
            <span class="_line">гүн болоод төрөл бүрийн аргаар дамжин хүрээсэй гэж хүсч байна. Бусад асуултыг Чой Арун </span>
            <span class="_line">пастор(010-9036-2458, hi-bible@naver.com)-той холбогдон лавлана уу.</span>
        </p>
        <p class="last_word _cast _cast_5">
            <span class="_line">Бидний бүх л амьдрал Христ дотор</span>
            <span class="_line"> – Hi Bible(Сайн уу Библи)-тэй хамтардаг хүмүүс</span>
        </p>
    </div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.about[lang],
			desc: 'Hi Bible(Сайн уу Библи) нь Салим чуулганаас эрхлэн гаргаж буй бичлэг болон бусад библийн хичээлийн авлагийг хуваалцдаг цахим хуудас билээ.',
			keyword: 'about hibible, bible story',
			thumb: '/hibible/resource/images/ab_intro.svg',
		});
	},
}

</script>