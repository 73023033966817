<template>
    <div class="article_area">
        <h5 class=" _cast _cast_0">
            <span class="text">เกี่ยวกับ</span>
            <span class="hi_bible">Hi Bible</span>
        </h5>
        <p class=" _cast _cast_1">
            <span class="_line">Hi Bible เป็นเว็บไซต์ที่นําเสนอการศึกษาพระคัมภีร์และวิดีโอที่ผลิตโดย คริสตจักรซาลิม</span>
            <span class="_line">วิดีโอที่บอกถึงพระวจนะของพระเจ้าตามปฏิทินของคริสตจักร พระคัมภีร์ เนื้อหาการศึกษาพระคัมภีร์และการอ่านพระคัมภีร์สำหรับเด็ก</span>
            <span class="_line">"การศึกษาพระคัมภีร์แบบลำดับขั้น, การอ่านพระคัมภีร์!" มีการอัปโหลดฟรีทุกวันอาทิตย์ บนเว็บไซต์ Hi Bible, </span>
            <span class="_line">Godpeople, Youtube และ Facebook.</span>
        </p>
        <p class=" _cast _cast_2">
            <span class="_line">คริสตจักร สามารถใช้สื่อ Hi Bible ได้โดยไม่ต้องมีศิษยาภิบาลคริสตจักรเด็ก</span>
            <span class="_line">ครอบครัวที่ต้องใช้เอกสารการศึกษาพระคัมภีร์ทุกสัปดาห์ และมิชชันนารีทั่วโลกที่ต้องการเนื้อหาพระคัมภีร์สำหรับเด็ก </span>
        </p>
        <p class=" _cast _cast_3">
            <span class="_line">Hi Bible จัดทำโดยศาสนาจารย์ อารอน ชอย และผู้ช่วยศิษยาภิบาล ศาสนาจารย์</span>
            <span class="_line">เฮยจิน คิม ซึ่งทั้งคู่ได้รับ ปริญญาโทศาสนศาสตร์ สาขาคริสเตียนศึกษา จาก มหาวิทยาลัยเพรสไบทีเรียน</span>
            <span class="_line">และวิทยาลัยศาสนศาสตร์ วิดีโอเหล่านี้สร้างขึ้นโดยทีมงานมืออาชีพ</span>
            <span class="_line">ด้านศิลปะและสื่อมัลติมีเดียตั้งแต่เริ่มการผลิตจนถึงการผลิตสำเร็จ</span>
        </p>
        <p class=" _cast _cast_4">
            <span class="_line">เราหวังเป็นอย่างยิ่งว่าพระวจนะของพระเจ้าจะเผยแพร่ไปทั่วโลกผ่านทาง Hi Bible</span>
            <span class="_line">และหยั่งรากลึกในหัวใจของเด็กๆ ทุกคนในโลก สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ ศาสนาจารย์ อารอน ชอย</span>
            <span class="_line">(โทร. +82.10-9036-2458 หรือ hi-bible@naver.com)</span>
        </p>
        <p class="last_word _cast _cast_5">
            <span class="_line">ชีวิตของเรารับใช้ร่วมกันในพระคริสต์</span>
            <span class="_line"> – ผ่านทาง Hi Bible!</span>
        </p>
    </div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.about[lang],
			desc: 'Hi Bible เป็นเว็บไซต์ที่นําเสนอการศึกษาพระคัมภีร์และวิดีโอที่ผลิตโดย คริสตจักรซาลิม วิดีโอที่บอกถึงพระวจนะของพระเจ้าตามปฏิทินของคริสตจักร พระคัมภีร์ เนื้อหาการศึกษาพระคัมภีร์และการอ่านพระคัมภีร์สำหรับเด็ก การศึกษาพระคัมภีร์แบบลำดับขั้น, การอ่านพระคัมภีร์!  มีการอัปโหลดฟรีทุกวันอาทิตย์ บนเว็บไซต์ Hi Bible, Godpeople, Youtube และ Facebook.',
			keyword: 'about hibible, bible story',
			thumb: '/hibible/resource/images/ab_intro.svg',
		});
	},
}
</script>