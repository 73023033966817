<template>
    <div class="article_area">
        <h5 class=" _cast _cast_0">
            <span class="hi_bible">Hi Bible</span>
            <span class="text">о эмблеме</span>
        </h5>
        <p class=" _cast _cast_1">
            <span class="_line">Hi Bible(Привет Библия) - это сайт, на котором публикуются видеоролики и материалы для изучения Библии, </span>
            <span class="_line">созданные церковью, Салим.Hi Bible(Привет Библия) бесплатно загружает каждую неделю проповедь, </span>
            <span class="_line">основанную на Библии и церковном календаре, с видеороликом, и “Пошаговые Действия”, программу, </span>
            <span class="_line">которая помогает детям читать и понимать Библию, на домашней странице Hi Bible, God People, YouTube и Facebook.</span>
        </p>
        <p class=" _cast _cast_2">
            <span class="_line">Материалы Hi Bible(Привет Библия) можно использовать в церквях, где нет пастора или проповедника для детей, </span>
            <span class="_line">в семьях, которым нужны материалы для религиозного образования, и в местах миссионерской деятельности, </span>
            <span class="_line">где не хватает материалов для религиозного образования для детей.</span>
        </p>
        <p class=" _cast _cast_3">
            <span class="_line">Все материалы Hi Bible((Привет Библия) планируются и выпускаются пастором Цой </span>
            <span class="_line">Ароном и проповедником Ким Хеджином, которые специализировались на христианском образовании </span>
            <span class="_line">в Пресвитерианском теологическом университете. Рисование, съемка и редактирование выполняются </span>
            <span class="_line">экспертами в данной области.</span>
        </p>
        <p class=" _cast _cast_4">
            <span class="_line">Я надеюсь, что слово Божье будет различными способами, глубоко передано детям земли через Hi Bible(Привет Библия).</span>
            <span class="_line">Если у вас есть какие-либо вопросы, пожалуйста, обращайтесь к пастору Цой Арону(010-9036-2458,hi-bible@naver.com).</span>
        </p>
        <p class="last_word _cast _cast_5">
            <span class="_line">Вся наша жизнь во Христе</span>
            <span class="_line"> – Сотрудники Hi Bible(Привет Библия).</span>
        </p>
    </div>
</template>

<script>
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'

export default {
	metaInfo(){
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.about[lang],
			desc: 'Hi Bible(Привет Библия) - это сайт, на котором публикуются видеоролики и материалы для изучения Библии',
			keyword: 'about hibible, bible story',
			thumb: '/hibible/resource/images/ab_intro.svg',
		});
	},
}

</script>