<template>
	<div class="main_wrap _ab">
		<h2
			v-if="lang=='kor'"
			class="section_title"
		>
			<a class="_curr">{{mainTitle}}</a>
			<span class="gutter">|</span>
			<router-link
				to="/map"
			>
				오시는 길
			</router-link>
		</h2>
		<h2
			v-if="lang!='kor'"
			class="section_title"
		>
			<span class="main_title">{{mainTitle}}</span>
			<span class="sub_title">{{subTitle}}</span>
		</h2>
		
		<div class="con_about">
			<section class="_intro">
				<div class="sec_wrap" id="introArea">
					<componunt :is="lang"></componunt>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import kor from './Kor'
import eng from './Eng'
import rus from './Rus'
import nep from './Nep'
import tai from './Tai'
import mog from './Mog'
import kaz from './Kaz'
import bur from './Bur'

export default{
	props: {
		
	},
	components:{
		kor, eng, rus, nep, tai, mog, kaz, bur
	},
	computed:{
		mainTitle(){
			return this.$store.state.headerTitle("about", "main");
		},
		subTitle(){
			return this.$store.state.headerTitle("about", "sub");
		}
	},
	data: () => ({
		lang:"",
	}),
	created(){
		let vm = this;
		vm.lang = vm.$store.state.lang;
	}
}
</script>